import React from "react"
import { Button, Flex, H2, P, colors } from "src/components"
import { Page } from "./Confirm"

export const Verified = () => (
  <Page>
    <div>
      <H2
        align="center"
        letterSpacing={0.5}
        margin="4rem"
        uppercase
        color={colors.lightRed}
        bold
        fontSize={3}>
        Підтверджено
      </H2>
      <P
        align="center"
        fontSize={2}
        margin="0px auto"
        style={{ maxWidth: "400px" }}>
        Найближчим часом Вам буде надіслано запрошення на тестування нашого
        додатку. Очікуйте!
      </P>
      <Flex margin="10rem" align="center" justify="center" gap={40}>
        <Button
          style={{
            fontSize: "2rem",
            padding: "1.5rem 3.5rem",
            borderRadius: "3rem",
          }}
          onClick={() => {
            window.location.assign("https://credo.pro")
          }}>
          На головну
        </Button>
      </Flex>
    </div>
  </Page>
)
