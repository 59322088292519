import React from "react"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"
import { Formik, Form } from "formik"
import { Input, Radio, TextLabel, Button, Flex, P } from "src/components"

interface Values {
  name: string
  email: string
  deviceType: "phone" | "tablet" | "tv"
  brand: string
  model: string
}

export const Signup = ({ device }: { device: string }) => {
  const [error, setError] = React.useState("")
  const navigate = useNavigate()

  const validate = (values: Values) => {
    const errors: {
      name?: string
      email?: string
      deviceType?: "phone" | "tablet" | "tv"
      brand?: string
      model?: string
    } = {}

    if (values.name.length < 4) {
      errors.name = "Введіть Ваше повне ім'я"
    } else if (values.name.split(" ").length < 2) {
      errors.name = "Введіть Ваше повне ім'я"
    } else if (values.name.split(" ")[1].length < 2) {
      errors.name = "Введіть Ваше повне ім'я"
    } else {
      errors.name = undefined
    }

    if (!values.email) {
      errors.email = "Введіть електронну адресу"
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Введіть Вашу дійсну електронну адресу"
    } else {
      errors.email = undefined
    }

    if (!values.brand) {
      errors.brand = "Введіть назву марки Вашого пристрою"
    }

    if (!values.model) {
      errors.model = "Введіть назву моделі Вашого пристрою"
    }
    return errors
  }

  const handleSubmit = async (values: Values) => {
    setError("")
    const request = await fetch(`${window.location.origin}/api/v1/signup`, {
      method: "POST",
      headers: new Headers({ "content-type": "application/json" }),
      body: JSON.stringify(values),
    })
    if (request.status === 200) {
      navigate("/confirm")
    }
    if (request.status === 400 || request.status === 404) {
      setError("E-mail вже використовується")
    }
  }

  return (
    <Page>
      {(device === "desktop" || device === "tablet-landscape") && (
        <Filler>
          <img src="/images/CREDO1.svg" alt="" />
        </Filler>
      )}
      <Formik
        initialValues={{
          name: "",
          email: "",
          deviceType: "phone",
          brand: "",
          model: "",
        }}
        validate={validate}
        onSubmit={handleSubmit}>
        {({ values, touched, handleChange, handleBlur }) => {
          const errors = validate(values)
          const submittable = Object.values(errors).reduce(
            (prev: boolean, curr: string) => prev && !curr,
            true,
          )
          return (
            <FormWrapper device={device}>
              <div>
                <Form>
                  <Input
                    label="Ім'я"
                    type="text"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    maxLength={50}
                    minLength={4}
                    placeholder=""
                  />
                  {errors.name && touched.name && (
                    <ErrorText>
                      <P uppercase style={{ letterSpacing: ".2rem" }}>
                        {errors.name}
                      </P>
                    </ErrorText>
                  )}
                  <Input
                    label="Email"
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    maxLength={50}
                    minLength={4}
                    placeholder=""
                  />
                  {errors.email && touched.email && (
                    <ErrorText>
                      <P uppercase style={{ letterSpacing: ".2rem" }}>
                        {errors.email}
                      </P>
                    </ErrorText>
                  )}
                  <TextLabel>
                    <Flex
                      row
                      justify="space-between"
                      nowrap
                      margin=".5rem 0px 0px 0px">
                      {[
                        { label: "Телевізор", value: "tv" },
                        { label: "Телефон", value: "phone" },
                        { label: "Планшет", value: "tablet" },
                      ].map(value => (
                        <Radio
                          key={value.label}
                          label={
                            value.label[0].toUpperCase() + value.label.slice(1)
                          }
                          value={value.value}
                          name="deviceType"
                        />
                      ))}
                    </Flex>
                    Вид пристрою
                  </TextLabel>
                  <Input
                    label="Назва пристрою"
                    type="text"
                    name="brand"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.brand}
                    maxLength={20}
                    minLength={4}
                    placeholder=""
                  />
                  {errors.brand && touched.brand && (
                    <ErrorText>
                      <P uppercase style={{ letterSpacing: ".2rem" }}>
                        {errors.brand}
                      </P>
                    </ErrorText>
                  )}
                  <Input
                    label="Модель пристрою"
                    type="text"
                    name="model"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.model}
                    maxLength={20}
                    minLength={1}
                    placeholder=""
                  />
                  {errors.model && touched.model && (
                    <ErrorText>
                      <P uppercase style={{ letterSpacing: ".2rem" }}>
                        {errors.model}
                      </P>
                    </ErrorText>
                  )}
                  {error && (
                    <P
                      color="#dddd00"
                      uppercase
                      fontSize={1.25}
                      align="center"
                      style={{ letterSpacing: ".2rem", margin: "1rem 0" }}>
                      {error}
                    </P>
                  )}
                  <Button
                    disabled={!submittable}
                    type="submit"
                    onClick={() => handleSubmit(values)}
                    style={{
                      margin: "5rem auto 0px",
                      width: "15.2rem",
                      display: "block",
                    }}>
                    Надіслати
                  </Button>
                </Form>
              </div>
            </FormWrapper>
          )
        }}
      </Formik>
    </Page>
  )
}

const Page = styled.div`
  box-sizing: border-box;
  width: 100vw;
  min-height: calc(100vh - 7rem);
  display: flex;
  flex-flow: row nowrap;
`
const FormWrapper = styled.div<{ device: string }>`
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-flow: column nowrap;
  > div {
    width: 80%;
    max-width: 300px;
    margin: ${({ device }) =>
      device.startsWith("mobile") || device === "tablet-portrait"
        ? "2rem auto"
        : "2rem 0px"};
  }
`
const Filler = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  > img {
    width: 70%;
    height: auto;
    max-width: 42.5rem;
    margin-right: 10%;
  }
`
const ErrorText = styled.div`
  position: absolute;
  transform: translateY(-3rem);
`
