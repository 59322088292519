import React from "react"
import styled from "styled-components"
import { Button, Flex, H2, P, colors } from "src/components"

export const Confirm = () => (
  <Page>
    <div>
      <H2
        align="center"
        letterSpacing={0.5}
        margin="4rem"
        uppercase
        color={colors.lightRed}
        bold
        fontSize={3}>
        Очікую <br />
        підтвердження
      </H2>
      <P align="center" fontSize={2} style={{ overflowWrap: "break-word" }}>
        Лист надіслано на Вашу <br />
        електронну адресу
      </P>
      <Flex margin="10rem" align="center" justify="center" gap={40}>
        <Button
          style={{
            fontSize: "2rem",
            padding: "1.5rem 3.5rem",
            borderRadius: "3rem",
          }}
          onClick={() => {
            window.location.assign("https://credo.pro")
          }}>
          На головну
        </Button>
      </Flex>
    </div>
  </Page>
)

export const Page = styled.div`
  box-sizing: border-box;
  width: 100vw;
  min-height: calc(100vh - 7rem);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`
