import React from "react"
import styled from "styled-components"
import { P, colors } from "src/components"

export const License = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "calc(100vh - 70px)",
    }}>
    <Wrapper>
      <P fontSize={2}>
        CREDO 24 Політика конфіденційності та умови використання
      </P>
      <P fontSize={2}>Версія 1.0, Липень 2022</P>
      <P fontSize={2}>Copyright © 2022 о. Микола Мишовський</P>
      <PBreak />
      <P fontSize={2}>Визначення термінів</P>
      <P fontSize={1.5}>
        <span style={{ color: colors.red }}>Програмне забезпечення</span>{" "}
        означає будь-який вихідний код, документацію або файли конфігурації, що
        належать БО &quot;CREDO&quot; та додатку &quot;CREDO&quot;.
      </P>
      <P fontSize={1.5}>
        <span style={{ color: colors.red }}>Власник ліцензії</span> означає
        власника прав на інтелектуальну власність &quot;CREDO&quot;, або інший
        суб&apos;єкт, якому БО &quot;CREDO&quot; було надано дозвіл на
        користування чи розпоряджання такою власністю.
      </P>
      <P fontSize={1.5}>
        <span style={{ color: colors.red }}>Контент</span> це продукція
        авторства &quot;CREDO&quot;, включно з (але не обмежено) авторськими
        зображеннями, відео та статтями.
      </P>
      <PBreak />
      <SingleIndentWrapper>
        <P fontSize={1.5} style={{ paddingRight: "10px" }}>
          1.
        </P>
        <P fontSize={1.5} style={{ flex: "1" }}>
          Це програмне забезпечення використовує наступні джерела відкритих
          даних згідно з правилами їх використання. Користувачі даного додатку
          також зобов&quot;язуються дотримуватись умов ліцензування та
          користування ними, включно з (але не обмежено) обмеженнями щодо
          поширення їх програмного забезпечення.
        </P>
      </SingleIndentWrapper>
      <DoubleIndentWrapper>
        <P fontSize={1.5} style={{ paddingRight: "10px" }}>
          1.1
        </P>
        <P fontSize={1.5} style={{ flex: "1" }}>
          Expo SDK - https://github.com/expo/expo/blob/main/LICENSE
        </P>
      </DoubleIndentWrapper>
      <DoubleIndentWrapper>
        <P fontSize={1.5} style={{ paddingRight: "10px" }}>
          1.2
        </P>
        <P fontSize={1.5} style={{ flex: "1" }}>
          React - https://github.com/facebook/react/blob/main/LICENSE
        </P>
      </DoubleIndentWrapper>
      <DoubleIndentWrapper>
        <P fontSize={1.5} style={{ paddingRight: "10px" }}>
          1.3
        </P>
        <P fontSize={1.5} style={{ flex: "1" }}>
          React Native -
          https://github.com/facebook/react-native/blob/main/LICENSE
        </P>
      </DoubleIndentWrapper>
      <DoubleIndentWrapper>
        <P fontSize={1.5} style={{ paddingRight: "10px" }}>
          1.4
        </P>
        <P fontSize={1.5} style={{ flex: "1" }}>
          React Native Paper -
          https://github.com/callstack/react-native-paper/blob/main/LICENSE.md
        </P>
      </DoubleIndentWrapper>
      <DoubleIndentWrapper>
        <P fontSize={1.5} style={{ paddingRight: "10px" }}>
          1.5
        </P>
        <P fontSize={1.5} style={{ flex: "1" }}>
          TypeScript -
          https://github.com/microsoft/TypeScript/blob/main/LICENSE.txt
        </P>
      </DoubleIndentWrapper>
      <DoubleIndentWrapper>
        <P fontSize={1.5} style={{ paddingRight: "10px" }}>
          1.6
        </P>
        <P fontSize={1.5} style={{ flex: "1" }}>
          Babel - https://github.com/babel/babel/blob/main/LICENSE
        </P>
      </DoubleIndentWrapper>
      <PBreak />
      <SingleIndentWrapper>
        <P fontSize={1.5} style={{ paddingRight: "10px" }}>
          2.
        </P>
        <P fontSize={1.5} style={{ flex: "1" }}>
          Програмне забезпечення додатку &quot;CREDO 24&quot; може бути
          поширеним лише за умови отримання письмового дозволу власника
          ліцензії, включно із вказаною ліцензією. Розповсюдження програмного
          забезпечення додатку &quot;CREDO 24&quot; з метою отримання фінансової
          вигоди суворо заборонено та є підставою для притягнення порушника до
          відповідальності через судовий позов.
        </P>
      </SingleIndentWrapper>
      <PBreak />
      <SingleIndentWrapper>
        <P fontSize={1.5} style={{ paddingRight: "10px" }}>
          3.
        </P>
        <P fontSize={1.5} style={{ flex: "1" }}>
          Так само, авторські значки, логотипи та інші торгові знаки,
          представлені у додатку &quot;CREDO 24&quot;, є
          &quot;суб&apos;єктом&quot; вищевказаної заборони на неавторизоване
          поширення без письмової згоди правовласника.Будь-який авторський
          контент даного додатку мже бути поширеним в іншій формі із чітким
          посиланням на першоджерело.
        </P>
      </SingleIndentWrapper>
      <PBreak />
      <SingleIndentWrapper>
        <P fontSize={1.5} style={{ paddingRight: "10px" }}>
          4.
        </P>
        <P fontSize={1.5} style={{ flex: "1" }}>
          Внесення змін, які можуть спричинити шкоду, до цього програмного
          забезпечення, або втручання у користування ним іншими користувачами
          (включно із розповсюдженням атак типу &quot;вмова в
          обслуговуванні&quot; на сервер) є підставою для цивільної,
          господарської та/або кримінальної відповідальності.
        </P>
      </SingleIndentWrapper>
      <PBreak />
      <SingleIndentWrapper>
        <P fontSize={1.5} style={{ paddingRight: "10px" }}>
          5.
        </P>
        <P fontSize={1.5} style={{ flex: "1" }}>
          Дані користувачів та бази даних користувачів в жодному випадку не
          розповсюджуються CREDO. Збір інформації чи метаданих не відбувається
          через додаток. Єдині дані, які зберігає CREDO, - ті, які були надані
          користувачем за його згодою через спілкування електронною поштою або
          під час внесення пожертв. Ці дані не розповсюджуються третім особам,
          та зберігатимуться лише допоки є корисними для конкретного користувача
          CREDO.
        </P>
      </SingleIndentWrapper>
      <PBreak />
      <SingleIndentWrapper>
        <P fontSize={1.5} style={{ paddingRight: "10px" }}>
          6.
        </P>
        <P fontSize={1.5} style={{ flex: "1" }}>
          Дане програмне забезпечення опубліковано для використання безкоштовно,
          без жодних інших гарантій чи обов&apos;язків.
        </P>
      </SingleIndentWrapper>
    </Wrapper>
  </div>
)

const PBreak = styled.div`
  height: 15px;
`
const Wrapper = styled.article`
  text-align: left;
  margin: 0px 50px 75px;
  max-width: 800px;

  @media screen and (max-width: 500px) {
    margin: 0px;
    padding: 0px 15px 75px;
    box-sizing: border-box;
    width: 100%;
  }
`
const SingleIndentWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
`
const DoubleIndentWrapper = styled(SingleIndentWrapper)`
  padding-left: 35px;
`
