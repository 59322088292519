import React from "react"
import styled from "styled-components"
import { H1, ButtonLink } from "src/components"

export const Home = ({ device }: { device: string }) => (
  <Wrapper device={device}>
    <section>
      <H1>Розвивай свій мозок і свою душу. Прямуй до Бога разом з нами.</H1>
      <ImageWrapper>
        <img src="/images/search-midnight.png" alt="" />
        <img src="/images/menu-midnight.png" alt="" />
        <img src="/images/donate-midnight.png" alt="" />
      </ImageWrapper>
      <ButtonLink buttontype="secondary" to="/signup">
        Ранній доступ
      </ButtonLink>
    </section>
    <img src="/images/home-midnight.png" alt="" />
  </Wrapper>
)

const Wrapper = styled.article<{ device: string }>`
  width: 100%;
  min-height: calc(100vh - 7rem);
  padding: 2rem;
  display: flex;
  flex-flow: ${({ device }) =>
      !device.startsWith("mobile") ? "row" : "column-reverse"}
    nowrap;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  > img {
    width: ${({ device }) => (!device.startsWith("mobile") ? "auto" : "80vw")};
    height: ${({ device }) =>
      !device.startsWith("mobile") ? "90vmin" : "auto"};
  }
  > section {
    width: ${({ device }) => (!device.startsWith("mobile") ? "35vw" : "100%")};
    text-align: ${({ device }) =>
      !device.startsWith("mobile") ? "left" : "center"};
    max-width: 500px;
    position: relative;
    > a {
      margin: 2rem auto;
      display: block;
      width: fit-content;
    }
  }
`
const ImageWrapper = styled.div`
  display: flex;
  flex-flow: row-wrap;
  width: 100%;
  position: relative;
  margin: 2rem 0px;
  > * {
    flex: 1;
    width: 30%;
    opacity: 0.7;
  }
`
