import React from "react"
import ReactHlsPlayer from "react-hls-player"

export const Live = () => {
  const playerRef = React.useRef<HTMLVideoElement | null>(null)

  return (
    <div
      style={{
        height: "calc(100vh - 70px)",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
      <ReactHlsPlayer
        playerRef={playerRef}
        src="https://titans.km.ua:30443/live_site/credo/stream_1/playlist.m3u8"
        autoPlay={true}
        controls={true}
        hlsConfig={{
          autoStartLoad: true,
          startPosition: -1,
          debug: false,
        }}
        style={{
          height: "auto",
          width: "80vw",
          maxWidth: "1200px",
          boxShadow: "rgba(208, 255, 255, 0.76) 0px 5px 15px",
          borderRadius: "5px",
        }}
      />
    </div>
  )
}
