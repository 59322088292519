import styled from "styled-components"
import { colors } from "./Typography"

type justify =
  | "flex-start"
  | "flex-end"
  | "center"
  | "space-between"
  | "space-around"
  | "space-evenly"

type align = "flex-start" | "flex-end" | "center" | "baseline" | "stretch"

export const Flex = styled.div<{
  justify?: justify
  align?: align
  gap?: number
  row?: boolean
  column?: boolean
  wrap?: boolean
  nowrap?: boolean
  reverse?: boolean
  padding?: string
  margin?: string
}>`
  display: flex;
  justify-content: ${({ justify }) => (justify ? justify : "flex-start")};
  align-content: ${({ align }) => (align ? align : "flex-start")};
  gap: ${({ gap }) => (gap ? gap + "px" : "none")};
  padding: ${({ padding }) => (padding ? padding : "0px")};
  margin: ${({ margin }) => (margin ? margin : "0px")};
  flex-flow: ${({ row, column, wrap, nowrap, reverse }) => {
    const direction = column ? "column" : row ? "row" : ""
    const stringifiedReverse = reverse ? "-reverse" : ""
    const stringifiedWrap = nowrap ? " nowrap" : wrap ? " wrap" : ""
    return direction + stringifiedReverse + stringifiedWrap
  }};
`

export const Header = styled.header`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 2rem;
  > img {
    height: 3rem;
    cursor: pointer;
  }
`
export const TableContainer = styled.article`
  &&& {
    max-width: 120rem;
    margin: 14rem auto;
    > div {
      &:focus {
        box-shadow: none;
      }
      &::-webkit-scrollbar {
        background-color: transparent;
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #d0021b;
        border-radius: 1rem;
      }
      > table {
        > thead > tr {
          border: none;
          > th {
            background-color: #333;
            border: none;
          }
          > th:first-of-type {
            border-top-left-radius: 5px;
          }
          > th:last-of-type {
            border-top-right-radius: 5px;
          }
        }
        > tbody > tr {
          cursor: pointer;
          &: hover {
            background-color: ${colors.mediumGrey};
          }
        }
        > tbody > tr > td {
          border-bottom: 1px solid ${colors.mediumGrey};
          min-width: 100px;
          &:last-of-type {
            > div > div {
              background-color: ${colors.lightGrey};
              &: hover {
                background-color: ${colors.white};
                > div {
                  background-color: ${colors.lightGreen};
                }
              }
              > div {
                background-color: ${colors.green};
              }
            }
          }
        }
      }
    }
  }
`
