export const determineDeviceType = ({
  width,
  height,
}: {
  width: number
  height: number
}) => {
  if (width > 1200 || width === undefined) {
    return "desktop"
  } else if (width > 768 && height > 481 && width > height) {
    return "tablet-landscape"
  } else if (height > 768 && width > 481 && height > width) {
    return "tablet-portrait"
  } else if (width > height) {
    return "mobile-landscape"
  } else {
    return "mobile-portrait"
  }
}
