import React from "react"
import {
  Routes,
  Route,
  useLocation,
  useSearchParams,
  useNavigate,
} from "react-router-dom"
import styled from "styled-components"
import { Header, ButtonLink, colors } from "./components"
import { Confirm, Home, Signup, License, Live, Verified } from "./pages"
import { determineDeviceType } from "./helpers"

import "gestalt/dist/gestalt.css"

export const App = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [device, setDevice] = React.useState(() =>
    determineDeviceType({
      width: window.innerWidth,
      height: window.innerHeight,
    }),
  )

  const handleResize = () => {
    setDevice(
      determineDeviceType({
        width: window.innerWidth,
        height: window.innerHeight,
      }),
    )
  }

  React.useEffect(() => {
    const verifyUser = async (id: string, code: string) => {
      const request = await fetch(
        `${window.location.origin}/api/v1/signup/verify?id=${id}&code=${code}`,
        {
          method: "PUT",
        },
      )
      if (request.status === 200) {
        navigate("/verified")
      } else {
        navigate("/signup")
      }
    }

    const id = searchParams.get("id")
    const code = searchParams.get("code")
    const license = searchParams.get("license")

    if (!!id && !!code) {
      verifyUser(id, code)
    } else if (license === "mobile") {
      navigate("/license")
    } else if (
      ![
        "/signup",
        "/confirm",
        "/verified",
        "/dashboard",
        "/live",
        "/license",
      ].includes(location.pathname)
    ) {
      navigate("/")
    }
  }, [searchParams, navigate, location.pathname])

  React.useLayoutEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <Page>
      <Header>
        <img
          src="/images/banner.png"
          onClick={() => (window.location.href = "https://credo.pro")}
          alt="credo логотип"
          title="посилання на домашню сторінку credo"
        />
        <ButtonLink to="/live">Дивись</ButtonLink>
      </Header>
      <Routes>
        <Route path="/" element={<Home device={device} />} />
        <Route path="/signup" element={<Signup device={device} />} />
        <Route path="/live" element={<Live />} />
        <Route path="/confirm" element={<Confirm />} />
        <Route path="/license" element={<License />} />
        <Route path="/verified" element={<Verified />} />
      </Routes>
    </Page>
  )
}

const Page = styled.div`
  position: relative;
  width: 100vw;
  background: ${colors.black};
  background: linear-gradient(
    345deg,
    rgba(17, 20, 23, 1) 0%,
    rgba(53, 56, 59, 1) 100%
  );
`
