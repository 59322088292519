import styled, { StyledComponent } from "styled-components"

export enum Fonts {
  MONTERSERAT = `"Montserat", sans-serif`,
  PT_SERIF = `"PT Serif", serif`,
}
export const colors = {
  red: "#D0021B",
  lightRed: "#F40000",
  black: "#111417",
  darkGrey: "#212427",
  mediumGrey: "#4A4657",
  lightGrey: "#C5C5DD",
  white: "#F5F5F7",
  green: "#14A34B",
  lightGreen: "#1BE368",
}

export const H1 = styled.h1`
  display: inline;
  margin: 0px;
  padding: 0px;
  font-family: ${Fonts.MONTERSERAT};
  font-weight: 700;
  font-size: 5vw;
  color: ${colors.white};
  vertical-align: baseline;
  @media screen and (min-width: 1000px) {
    font-size: 5rem;
  }
`

export type align = "left" | "center" | "right"

export type verticalAlign =
  | "baseline"
  | "top"
  | "middle"
  | "bottom"
  | "sub"
  | "text-top"

export interface TypographyProps {
  children: string | React.ReactNode
  bold?: boolean
  italic?: boolean
  uppercase?: boolean
  margin?: string
  padding?: string
  align?: align
  fontSize?: number
  fontStyle?: string
  color?: string
  verticalAlign?: verticalAlign
  letterSpacing?: number
  style?: Record<string, string>
  onClick?: (e: unknown) => void
}

export const p = styled.p`
  font-family: ${Fonts.MONTERSERAT};
  color: ${colors.white};
  margin-top: 0px;
`

export const h2 = styled.h2`
  font-family: ${Fonts.PT_SERIF};
  color: ${colors.white};
`

const createStyledText = (component: StyledComponent<any, any, {}>) => {
  return styled(component)<Omit<TypographyProps, "children">>`
    color: ${colors.white};
    font-weight: ${({ bold }) => (bold ? 700 : 400)};
    font-style: ${({ italic }) => (italic ? "italic" : "normal")};
    font-size: ${({ fontSize }) => (fontSize ? fontSize + "rem" : "1rem")};
    line-height: ${({ fontSize }) =>
      fontSize ? fontSize * 1.5 + "rem" : "1.5rem"};
    color: ${({ color }) => (color ? color : colors.white)};
    margin: ${({ margin }) => (margin ? margin : "0px")};
    padding: ${({ padding }) => (padding ? padding : "0px")};
    text-align: ${({ align }) => (align ? align : "left")};
    letter-spacing: ${({ letterSpacing }) =>
      letterSpacing ? letterSpacing + "rem" : "initial"};
    vertical-align: ${({ verticalAlign }) =>
      verticalAlign ? verticalAlign : "baseline"};
    text-transform: ${({ uppercase }) => (uppercase ? "uppercase" : "none")};
  `
}
const DSP = createStyledText(p)
const DSH2 = createStyledText(h2)

export const P = (props: TypographyProps) => {
  const { children, ...rest } = props
  return <DSP {...rest}>{children}</DSP>
}

export const H2 = (props: TypographyProps) => {
  const { children, ...rest } = props
  return <DSH2 {...rest}>{children}</DSH2>
}
