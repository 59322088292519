import { Link } from "react-router-dom"
import styled from "styled-components"
import { Fonts, colors } from "./Typography"

const buttonStyles = `
  border-radius: 2rem;
  padding: 1rem 2rem;
  cursor: pointer;
  font-family: ${Fonts.MONTERSERAT};
  font-weight: 600;
  font-size: 1.5rem;
  color: ${colors.white};
`
export const Button = styled.button<{
  buttontype?: string
  disabled?: boolean
  type?: string
  onClick?: (e: any) => any
}>`
  border: ${({ buttontype }) =>
    buttontype === "secondary"
      ? "2px solid " + colors.white
      : "2px solid transparent"};
  ${buttonStyles}
  background-color: ${({ buttontype }) =>
    buttontype === "secondary" ? "transparent" : colors.green};
  &:hover {
    border: 2px solid transparent;
    background-color: ${({ buttontype }) =>
      buttontype === "secondary" ? colors.green : colors.darkGrey};
  }
  &:disabled {
    background-color: ${({ disabled }) => (disabled ? "#666" : "")};
    cursor: not-allowed;
  }
`
export const ButtonLink = styled(Link)<{ buttontype?: string; to: string }>`
  border: ${({ buttontype }) =>
    buttontype === "secondary"
      ? "2px solid " + colors.white
      : "2px solid transparent"};
  ${buttonStyles}
  text-decoration: none;
  background-color: ${({ buttontype }) =>
    buttontype === "secondary" ? "transparent" : colors.green};
  &:hover {
    border: 2px solid transparent;
    background-color: ${({ buttontype }) =>
      buttontype === "secondary" ? colors.green : colors.darkGrey};
  }
`
