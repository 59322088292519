import styled from "styled-components"
import { Field } from "formik"
import { Fonts, colors } from "./Typography"

interface InputProps {
  label?: string
  value: string
  type: "text" | "email" | "search"
  name: string
  onChange: ({ target }: { target: HTMLInputElement }) => void
  onBlur?: ({ target }: { target: HTMLInputElement }) => void
  placeholder?: string
  maxLength?: number
  minLength?: number
  required?: boolean
  pattern?: string
  title?: string
}

export const Input = ({
  label,
  value,
  type,
  name,
  onChange,
  onBlur,
  placeholder,
  maxLength,
  minLength,
  required,
  pattern,
  title,
}: InputProps) => {
  return (
    <TextLabel>
      <StyledInput
        autoComplete="off"
        value={value}
        type={type}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        maxLength={maxLength}
        minLength={minLength}
        required={required}
        pattern={pattern}
        title={title}
      />
      {label}
    </TextLabel>
  )
}

const StyledInput = styled.input`
  background: transparent !important;
  border: none;
  border-bottom: 1px solid #666;
  margin: 0.5rem 0;
  height: 2rem;
  color: ${colors.white};
  font-family: ${Fonts.MONTERSERAT};
  letter-spacing: 0.2rem;
  &::placeholder {
    font-family: ${Fonts.MONTERSERAT};
    text-transform: uppercase;
  }
  &:focus {
    outline: none;
  }
`
export const TextLabel = styled.label`
  display: flex;
  flex-flow: column-reverse nowrap;
  gap: 0.5rem;
  color: ${colors.red};
  font-family: ${Fonts.PT_SERIF};
  font-size: 2rem;
  text-transform: uppercase;
  margin: 3rem 0px;
`

interface RadioProps {
  label?: string
  value: string
  name: string
}

export const Radio = ({ label, value, name }: RadioProps) => {
  return (
    <RadioLabel>
      <Field value={value} type="radio" name={name}>
        {({ field, form }: { field: any; form: any }) => {
          const { name, value } = field
          const { setValues } = form
          return (
            <Checkbox
              onClick={() =>
                setValues((prev: any) => ({
                  ...prev,
                  [name]: value,
                }))
              }
              style={{
                backgroundColor: field.checked ? colors.green : colors.white,
              }}></Checkbox>
          )
        }}
      </Field>
      {label}
    </RadioLabel>
  )
}

const RadioLabel = styled.label`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
  width: fit-content;
  color: ${colors.red};
  font-family: ${Fonts.PT_SERIF};
  font-size: 1.25rem;
  letter-spacing: 0.1rem;
  vertical-align: middle;
  text-transform: uppercase;
`
const Checkbox = styled.div`
  width: 1.25rem;
  height: 1.25rem;
  margin: 0.125rem 0.5rem 0.125rem 0px;
  border-radius: 50%;
  border: 2px solid white;
`
